.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Шрифт для заголовков */
@font-face {
  font-family: Austin;
  src: url("../src/assets/fonts/AustinCyr-Bold.ttf");
}

@font-face {
  font-family: AustinMedium;
  src: url("../src/assets/fonts/AustinCyr-Medium.ttf");
}

/* Шрифт для обычного текста */
@font-face {
  font-family: PT-Sans;
  src: url("../src/assets/fonts/PTSans-Regular.ttf");
}

@font-face {
  font-family: PT-Sans-Bold;
  src: url("../src/assets/fonts/PTSans-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: PT-Sans-Italic;
  src: url("../src/assets/fonts/PTSans-Italic.ttf");
}

body {
  font-family: "PT-Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Austin", sans-serif;
}
