.preloader {
    height: 100vh;
    width: 100%;
    background: #288e81;
    color: #FFF;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 55;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.preloader .texts-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 250px;
    font-size: 20px;
    font-family: Austin;
    overflow: hidden;
    color: #FFF;
}